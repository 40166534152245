@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style-type: none;
  font-family: "Comfortaa", sans-serif;
}

body {
  background-color: #f5f4ef;
}

.pl {
  display: block;
  width: 9.375em;
  height: 9.375em;
}

.pl__arrows,
.pl__ring-rotate,
.pl__ring-stroke,
.pl__tick {
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.pl__arrows {
  animation-name: arrows42;
  transform: rotate(45deg);
  transform-origin: 16px 52px;
}

.pl__ring-rotate,
.pl__ring-stroke {
  transform-origin: 80px 80px;
}

.pl__ring-rotate {
  animation-name: ringRotate42;
}

.pl__ring-stroke {
  animation-name: ringStroke42;
  transform: rotate(-45deg);
}

.pl__tick {
  animation-name: tick42;
}

.pl__tick:nth-child(2) {
  animation-delay: -1.75s;
}

.pl__tick:nth-child(3) {
  animation-delay: -1.5s;
}

.pl__tick:nth-child(4) {
  animation-delay: -1.25s;
}

.pl__tick:nth-child(5) {
  animation-delay: -1s;
}

.pl__tick:nth-child(6) {
  animation-delay: -0.75s;
}

.pl__tick:nth-child(7) {
  animation-delay: -0.5s;
}

.pl__tick:nth-child(8) {
  animation-delay: -0.25s;
}

/* Animations */
@keyframes arrows42 {
  from {
    transform: rotate(45deg);
  }

  to {
    transform: rotate(405deg);
  }
}

@keyframes ringRotate42 {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(720deg);
  }
}

@keyframes ringStroke42 {
  from,
  to {
    stroke-dashoffset: 452;
    transform: rotate(-45deg);
  }

  50% {
    stroke-dashoffset: 169.5;
    transform: rotate(-180deg);
  }
}

@keyframes tick42 {
  from,
  3%,
  47%,
  to {
    stroke-dashoffset: -12;
  }

  14%,
  36% {
    stroke-dashoffset: 0;
  }
}

.button {
  width: 110px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  background-color: rgb(161, 255, 20);
  border-radius: 30px;
  color: rgb(19, 19, 19);
  font-weight: 600;
  border: none;
  position: relative;
  cursor: pointer;
  transition-duration: 0.2s;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.116);
  padding-left: 8px;
  transition-duration: 0.5s;
}

.svgIcon {
  height: 25px;
  transition-duration: 1.5s;
}

.bell path {
  fill: rgb(19, 19, 19);
}

.button:hover {
  background-color: rgb(192, 255, 20);
  transition-duration: 0.5s;
}

.button:active {
  transform: scale(0.97);
  transition-duration: 0.2s;
}

.button:hover .svgIcon {
  transform: rotate(250deg);
  transition-duration: 1.5s;
}

.slick-dots li button:before {
  color: red; /* Красный цвет активной точки */
}

.slick-dots li.slick-active button:before {
  color: blue; /* Синий цвет при активной точке */
}

.swiper-container * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.swiper-container {
  box-sizing: border-box;
  width: 100%;
  height: 400px;
  transition: opacity 0.6s ease;
}
.swiper-container.swiper-container-coverflow {
  padding: 2% 0 calc(2% + 30px) 0;
}
.swiper-container:hover .swiper-button-prev,
.swiper-container:hover .swiper-button-next {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}
.swiper-slide {
  background-position: center;
  background-size: cover;
}
.swiper-slide .swiper-content {
  position: absolute;
  bottom: 4%;
  left: 2%;
  max-width: 70%;
  padding: 10px 16px;
  color: #fff;
  font-family: Verdana, sans-serif;
  background: rgba(0, 0, 0, 0.6);
}
.swiper-slide .swiper-content .swiper-title {
  font-size: 30px;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
}
.swiper-slide .swiper-content .swiper-caption {
  display: block;
  font-size: 15px;
  line-height: 1.4;
}
[class^="swiper-button-"] {
  width: 44px;
  opacity: 0;
  visibility: hidden;
}
.swiper-button-prev {
  transform: translateX(50px);
}
.swiper-button-next {
  transform: translateX(-50px);
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 10px;
  bottom: 10px;
  position: relative;
  width: 12px;
  height: 12px;
  background-color: #337ab7;
  opacity: 0.5;
  transition: all 0.3s ease;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  transform: translate(-50%, -50%);
  border: 1px solid #337ab7;
  border-radius: 50%;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet:hover,
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
}
@media (max-width: 767px) {
  .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    bottom: 4px;
  }
  .swiper-slide .swiper-content .swiper-title {
    font-size: 22px;
  }
  .swiper-slide .swiper-content .swiper-caption {
    font-size: 13px;
  }
}
